import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./ImageShuffle.module.scss";

const variants = {
    initial: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: {
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
        },
    },
    exit: (direction) => {
        return {
            x: direction > 0 ? -1000 : 1000,
            opacity: 0,
            transition: {
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
            },
        };
    },
};

const ImageShuffle = ({ media }) => {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState(0);

    function nextStep() {
        setDirection(1);
        if (index === media.length - 1) {
            setIndex(0);
            return;
        }
        setIndex(index + 1);
    }

    function prevStep() {
        setDirection(-1);
        if (index === 0) {
            setIndex(media.length - 1);
            return;
        }
        setIndex(index - 1);
    }

    return (
        <div className={styles.container}>
            <div className={styles.slideshow}>
                <AnimatePresence initial={false} custom={direction}>
                    {index === 0 && media[index].includes("mp4") ?  (
                        <motion.video variants={variants} animate="animate" initial="initial" exit="exit" controls width="100%" className={styles.vid}>
                            <source src={media[index]} type="video/mp4" />
                            Sorry, your browser doesn't support videos.
                        </motion.video>
                    ) : (
                        <motion.img variants={variants} animate="animate" initial="initial" exit="exit" src={media[index]} alt="slides" className={styles.slides} key={media[index]} custom={direction} />
                    )}
                </AnimatePresence>
                <button className={styles.prevButton} onClick={prevStep}>
                    ◀
                </button>
                <button className={styles.nextButton} onClick={nextStep}>
                    ▶
                </button>
            </div>
        </div>
    );
};

export default ImageShuffle;
