import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/styles/global.scss";
import App from "./App";
import ReactGA from 'react-ga';

// const trackingId = process.env.REACT_APP_ANALYTICS_TRACKING_ID

// ReactGA.initialize(trackingId);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

