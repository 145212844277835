import React, {useRef } from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import pictureBram from "./../assets/images/picture_bram.jpg";
import ScrollingText from "./ScrollingText";
import { Globe } from "phosphor-react";

import styles from "./Full.module.scss";
import Arrow from "../assets/shapes/Arrow";
import LocationHolder from "../assets/shapes/LocationHolder";
import Footer from "./Footer";

const Full = () => {
    const headerRef = useRef();
    const endOfFooterRef = useRef();
    const endOfHeaderRef = useRef();

    const {scrollYProgress } = useScroll({ target: headerRef });

    const yProgress = useTransform(scrollYProgress, [1, 0], [0, -225]);

    const onClickHandlerWork = () => {
        endOfHeaderRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const onClickHandlerContact = () => {
        endOfFooterRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <>
            <header className={styles.header}>
                <nav className={styles.navigation}>
                    <ul className={styles.ul}>
                        <li className={styles.li}>BRVM.</li>
                        <motion.li className={styles.li} whileHover={{scale: 1.05, color: "#ff4d5a"}} onClick={onClickHandlerWork}>
                            My Work
                        </motion.li>
                        <motion.li className={styles.li} whileHover={{scale: 1.05, color: "#ff4d5a"}} onClick={onClickHandlerContact}>
                            Contact
                        </motion.li>
                    </ul>
                </nav>
                <motion.div className={styles.content}>
                    <div className={styles.imageContainer}>
                        <img className={styles.imageBram} src={pictureBram} alt="Bram Mathijssen" />
                    </div>
                    <motion.div className={styles.skillsContainer} style={{ y: yProgress }}>
                        <div className={styles.skills}>
                            <div className={styles.arrow}>
                                <Arrow />
                            </div>
                            <span>Webdeveloper</span>
                            <span>Smart Contract Engineer</span>
                        </div>
                        <div className={styles.location}>
                            <div className={styles.icon}>
                                <Globe className={styles.globe} size={43} color={"white"} />
                            </div>
                            <p>
                                <span>Located </span>
                                <span>In the </span>
                                <span>Netherlands </span>
                            </p>
                            <LocationHolder />
                        </div>
                    </motion.div>
                </motion.div>
                <div className={styles.scrollTextContainer} ref={headerRef}>
                    <ScrollingText baseVelocity={1.5}>
                        Bram Mathijssen<span className={styles.spacer}>—</span>
                    </ScrollingText>
                </div>
            </header>
            <div ref={endOfHeaderRef} />
            <Footer endOfFooterRef={endOfFooterRef} />
        </>
    );
};

export default Full;
