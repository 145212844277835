import { motion, LayoutGroup, AnimatePresence } from "framer-motion";
import { useCallback, useState } from "react";
import AnimatedButton from "./AnimatedButton";
import { CaretRight } from "phosphor-react";
import { PiggyBank, AddressBook, ArrowRight, Hamburger, Ticket, Gavel } from "@phosphor-icons/react";

import styles from "./CardsModal.module.scss";
import ImageShuffle from "./ImageShuffle";

const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0,
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        transition: {
            duration: 1,
            ease: "easeInOut",
        },
    },
};

const CardsModal = () => {
    const [modalState, setModalState] = useState({
        open: false,
        index: null,
    });

    const handleClose = useCallback(() => {
        setModalState({ open: false, index: null });
    }, []);

    const items = [
        {
            index: 0,
            media: [
                require("./../assets/images/PiggybankDemo.mp4"),
                require("./../assets/images/parent_dash1.png"),
                require("./../assets/images/parent_dash2.png"),
                require("./../assets/images/parent_dash3.png"),
                require("./../assets/images/child_dash1.png"),
                require("./../assets/images/child_dash2.png"),
            ],
            name: "PiggyBank Crypto",
            icon: <PiggyBank size={38} />,
            subtitle: "Decentralized platform to educate children about saving cryptocurrencies",
            tech: ["Solidity", "JavaScript", "React", "Hardhat", "SASS"],
            description:
                "This application has been developed from the ground up. It is a decentralized platform that allows parents to educate their children about saving cryptocurrencies in a secure environment. Parents can create new tokens and keep them vested in the contract, and also set up a claiming schedule for their children to claim the cryptocurrency of their choice. The child can claim their tokens on a daily, weekly, or monthly basis. In order to promote patience, a 10% bonus will be given if the child selects the monthly interval, while a -10% reduction will be applied for the daily interval.",
            features: [
                "Parent: Mint new tokens and keep them vested in the contract",
                "Parent: Add children to the allowance list including their name, token and base allowance",
                "Child: Modify the token of their choice (which has been minted and vested by their parent)",
                "Child: Change their prefered claim period daily (-10%), weekly (base), monthly (+10%)",
                "Child: Claim the token of their choice after the claim moment has been reached",
                "90% Test coverage on the Solidity Contract",
            ],
            demo: "https://piggybank-dapp.vercel.app/",
            github: "https://github.com/BramMathijssen/piggybank-dapp",
        },
        {
            index: 1,
            media: [
                require("./../assets/images/DapperDaoDemo.mp4"),
                require("./../assets/images/dapper_dao_1.jpg"),
                require("./../assets/images/dapper_dao_2.jpg"),
                require("./../assets/images/dapper_dao_3.jpg"),
            ],
            name: "Dapper.Dao",
            icon: <Gavel size={38} />,
            subtitle: "dApp to run a decentralized autonomous organization",
            tech: ["React", "Vite", "TailwindCSS", "Shadcn-ui", "Chainlink", "Viem"],
            description:
                "A dApp to run a decentralized autonomous organization (DAO), registered admins can add other members to the DAO. Only members are allowed to vote on proposals. A proposal will be passed if it has over 50% upvotes after the vote period has ended. Each member is only allowed to vote one time on each proposal.",
            features: [
                "Role based access, only an Admin can add other members",
                "Creating proposals which only DAO members can vote on",
                "Daily, weekly or monthly duration for proposals",
            ],
            content: "Decentralized addressbook for storing all your favorite ethereum addresses",
            demo: "https://dao-dapp-five.vercel.app/",
            github: "https://github.com/BramMathijssen/dapper-dao"
        },
        {
            index: 1,
            media: [
                require("./../assets/images/AddressBookDemo.mp4"),
                require("./../assets/images/addressbook_1.png"),
                require("./../assets/images/addressbook_2.png"),
            ],
            name: "Addressbook dApp",
            icon: <AddressBook size={38} />,
            subtitle: "Web3 app to link a name to any Ethereum address",
            tech: ["Solidity", "JavaScript", "NextJS", "Hardhat", "SASS"],
            description:
                "An address book which allows users to store addresses linked to a name on-chain. Users can add as many addresses as they want, and every entry in the address book can be edited. Furthermore, entries can be deleted without leaving empty gaps in the array by shifting all entries one index to the left. The address book contract uses nested mappings to link the user's address to a mapping of the added address and name.",
            features: [
                "Every user can keep a personal address book stored on chain",
                "Addresses can be edited",
                "Entries in the address book can be deleted without leaving empty gaps",
            ],
            content: "Decentralized addressbook for storing all your favorite ethereum addresses",
            demo: "https://address-book-sepia.vercel.app/",
            github: "https://github.com/BramMathijssen/address-book",
        },
        {
            index: 1,
            media: [
                require("./../assets/images/PockyDemo.mp4"),
                require("./../assets/images/pocky_user_1.jpg"),
                require("./../assets/images/pocky_user_2.jpg"),
                require("./../assets/images/pocky_admin_1.jpg"),
                require("./../assets/images/pocky_admin_2.jpg"),
                require("./../assets/images/pocky_admin_3.jpg"),
            ],
            name: "POCKY",
            icon: <Ticket size={38} />,
            subtitle: "Dynamic NFT Ticketing Platform for Sports Events, powered by Chainlink automation",
            tech: ["React", "Vite", "Deno", "Solidity", "Chainlink", "Hardhat"],
            description:
                "The inspiration for POCKY came from the desire to revolutionize the sports ticketing industry and provide an enhanced experience for sports enthusiasts. We wanted to support the next level of the NFT ticketing system that enables more active engagement of consumers and gets them involved even after the event has ended We use Chainlink Oracle to update the dynamic NFT by fetching the match results using ESPN sports API. We implemented Chainlink Any API Consumer on Polygon to update the match results, and we use Chainlink Upkeep to fully automate the updating task.",
            features: [
                "Dynamic NFT Tickets: In contrast to conventional NFTs, dynamic NFTs in POCKY can have their metadata updated to reflect real-time information.",
                "POCKY's Special Smart Contracts: POCKY employs unique smart contracts to manage ticket issuance, updates, and validation.",
                "Real-time Event Updates: POCKY's automation system reflects changes over time using the dynamic NFT system, keeping ticket holders informed.",
                "Intuitive UI/UX Design: POCKY's user-friendly front-end allows fans to easily navigate the platform, browse upcoming events, and purchase tickets with their preferred digital wallet.",
            ],
            content: "Decentralized addressbook for storing all your favorite ethereum addresses",
            demo: "https://client-admin-pocky.vercel.app/",
            github: "https://github.com/Ticketin",
            devpost: "https://devpost.com/software/pocky",
        },
        {
            index: 1,
            media: [require("./../assets/images/lottery_dapp_2.png")],
            name: "Solidity Lottery",
            icon: <Ticket size={38} />,
            subtitle: "A decentralized lottery dApp based on Chainlink's random number generation",
            tech: ["Solidity", "NextJS", "JavaScript", "Chainlink", "Hardhat"],
            description:
                "A decentralized lottery that uses Chainlink's VRF (Verifiable Random Function) to generate random numbers. The lottery automatically generates a random number and selects a winner using Chainlink Keeper, which automatically calls the Raffle contract at a predefined interval. The winner will receive the prize money sent to their address by the smart contract, and a new lottery round will begin again.",
            features: [
                "Chainlink VRF random number generator to pick a random winner",
                "Chainlink Keeper's to automatically pick a winner on a predefined interval",
                "Sending the prize money to the winner after the winner has been picked",
            ],
            content: "Decentralized addressbook for storing all your favorite ethereum addresses",
            demo: "",
            github: "",
        },

        {
            index: 1,
            media: [
                require("./../assets/images/ReactMealsDemo.mp4"),
                require("./../assets/images/reactmeals_1.png"),
                require("./../assets/images/reactmeals_2.png"),
            ],
            name: "Food Order App",
            icon: <Hamburger size={38} />,
            subtitle: "A food ordering app with Firebase integration and shopping cart management",
            tech: ["JavaScript", "React", "Firebase", "SASS"],
            description:
                "Introducing a food ordering app that retrieves meals from a Firebase database and displays them on the front-end. Users can add and remove meals from the shopping cart, and the total price will be automatically calculated based on the contents in the cart. Once an order is placed, it will be securely stored in the Firebase database",
            features: [
                "Retrieving meals from a Firebase database through API calls",
                "After an order has been placed, the order will be stored in the database",
                "Using the useReducer hook to handle price calculations in the shopping cart",
            ],
            content: "Decentralized addressbook for storing all your favorite ethereum addresses",
            demo: "https://food-order-app-nine-ruddy.vercel.app/",
            github: "https://github.com/BramMathijssen/food-order-app",
        },
    ];

    return (
        <div className={styles.cards}>
            <LayoutGroup>
                <ul className={styles.collection}>
                    {items.map((item, index) => (
                        <>
                            {modalState.index !== index ? (
                                <motion.li
                                    key={index}
                                    whileHover={{ scale: 1.05 }}
                                    layout
                                    className={styles.single}
                                    layoutId={`${index}`}
                                    onClick={() => setModalState({ open: !modalState.open, index: index })}
                                >
                                    <motion.div className={styles.placeholder} layoutId={`placeholder-${index}`}>
                                        <h2>
                                            <span>{item.icon} </span>
                                            <span>{item.name}</span>
                                        </h2>
                                        <h3>{item.subtitle}</h3>
                                        <div className={styles.badges}>
                                            {item.tech.map((item, index) => (
                                                <p key={index} className={styles.technology}>
                                                    {item}
                                                </p>
                                            ))}
                                        </div>
                                    </motion.div>
                                    <motion.div className={styles.additional} layoutId={`additional-${index}`}>
                                        <div className={styles.placeholderBar1}></div>
                                        <div className={styles.placeholderBar2}></div>
                                        <div className={styles.flexWrapper}>
                                            <div className={styles.placeholderBar3}></div>
                                            <div className={styles.arrowIcon}>
                                                <ArrowRight size={35} />
                                            </div>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        className={styles.itemClose}
                                        layoutId={`close-${index}`}
                                        initial={{ opacity: 0 }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                            <path
                                                d="M15 5L5 15M5 5l5.03 5.03L15 15"
                                                fill="transparent"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </motion.div>
                                </motion.li>
                            ) : (
                                <li key={index}></li>
                            )}
                        </>
                    ))}
                </ul>
                <AnimatePresence>
                    {modalState.open ? (
                        <>
                            <motion.div className={styles.modalContainer} key="modal">
                                <motion.div className={styles.modal} layoutId={`${modalState.index}`}>
                                    <motion.div
                                        className={styles.placeholder}
                                        layoutId={`placeholder-${modalState.index}`}
                                    >
                                        <h1>{items[modalState.index].name}</h1>
                                        <div className={styles.flexContainer}>
                                            <div className={styles.leftSideContainer}>
                                                <ImageShuffle media={items[modalState.index].media} />
                                                <div className={styles.linksWrapper}>
                                                    <a className={styles.link} href={items[modalState.index].github}>
                                                        <AnimatedButton content="Code" />
                                                    </a>
                                                    <a className={styles.link} href={items[modalState.index].demo}>
                                                        <AnimatedButton content="Live" />
                                                    </a>
                                                    {items[modalState.index].devpost ? (
                                                        <a className={styles.link} href={items[modalState.index].devpost}>
                                                            <AnimatedButton content="Devpost" />
                                                        </a>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className={styles.rightSideContainer}>
                                                <div className={styles.description}>
                                                    <h3>Description</h3>
                                                    <p>{items[modalState.index].description}</p>
                                                </div>
                                                <div className={styles.features}>
                                                    <h3>Features</h3>
                                                    {items[modalState.index].features.map((feature, index) => (
                                                        <p key={index} className={styles.caret}>
                                                            <CaretRight size={25} />
                                                            <span>{feature}</span>
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                    <motion.div
                                        className={styles.additional}
                                        layoutId={`additional-${modalState.index}`}
                                    >
                                        <div />
                                        <div />
                                    </motion.div>
                                    <motion.div
                                        className={styles.modalClose}
                                        layoutId={`close-${modalState.index}`}
                                        onClick={handleClose}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <motion.svg
                                            initial="hidden"
                                            animate="visible"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <motion.path
                                                variants={pathVariants}
                                                d="M15 5L5 15M5 5l5.03 5.03L15 15"
                                                fill="transparent"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                            />
                                        </motion.svg>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            <motion.div
                                className={styles.modalBackdrop}
                                key="backdrop"
                                onClick={handleClose}
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        transition: {
                                            duration: 0.16,
                                        },
                                    },
                                    visible: {
                                        opacity: 0.8,
                                        transition: {
                                            delay: 0.04,
                                            duration: 0.2,
                                        },
                                    },
                                }}
                                initial="hidden"
                                exit="hidden"
                                animate="visible"
                            />
                        </>
                    ) : null}
                </AnimatePresence>
            </LayoutGroup>
        </div>
    );
};

export default CardsModal;
