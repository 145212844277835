import { useScroll, useTransform, motion, useSpring } from "framer-motion";
import React, { useRef } from "react";
import CardsModal from "./CardsModal";
import pictureBram from "./../assets/images/round_picture_bram.png";

import styles from "./Footer.module.scss";
import AnimatedTextCharacter from "./AnimatedTextCharacter";

const Footer = ({endOfFooterRef}) => {
    const roundedRef = useRef();
    const footer = useRef();

    const { scrollYProgress } = useScroll({ target: roundedRef, footer });

    const yProgress = useTransform(scrollYProgress, [1, 0], [2000, 0]);
    const yProgSpring = useSpring(yProgress, { stiffness: 200, damping: 10 });
    const yFooter = useTransform(scrollYProgress, [1, 0], [-400, 0]);
    const xBall = useTransform(scrollYProgress, [1, 0], ['-10%', '-120%']);

    return (
        <>
            <motion.section className={styles.middleSection}>
                <div className={styles.contentContainer}>
                    <motion.div
                        className={styles.introduction}
                    >
                        <div className={styles.textWrapper}>
                            <AnimatedTextCharacter text={"Crafting elegant dApps which stand out"} />
                            <AnimatedTextCharacter text={"in the decentralized era."} />
                        </div>
                    </motion.div>

                    <div className={styles.cardsContainer}>
                        <h3>My Work</h3>
                        <CardsModal />
                    </div>
                </div>
            </motion.section>
            <motion.div className={styles.roundedEndContainer} ref={roundedRef}>
                <motion.div layout style={{ height: yProgSpring }} className={styles.roundedEnd}></motion.div>
            </motion.div>
            <motion.section className={styles.footerSection} layout style={{ y: yFooter }} > 
                <div className={styles.contentContainer}>
                    <div className={styles.footerIntro}>
                        <span>
                            <img className={styles.pictureBram} src={pictureBram} alt="Bram" />
                            <h2>Let's get</h2>
                        </span>
                        <h2>in touch</h2>
                    </div>
                    <div className={styles.lineContainer}>
                        <div className={styles.line}></div>
                        <motion.div style={{ x: xBall, y: '-50%'}} className={styles.ball}>
                            <p>
                                Leave me <span> a message</span>
                            </p>
                        </motion.div>
                    </div>
                    <div className={styles.contactContainer}>
                        <div className={styles.email}>
                            <p>bram.mathijssen@gmail.com</p>
                        </div>
                        <div className={styles.phone}>
                            <p>+31 6 50 22 05 03</p>
                        </div>
                    </div>
                    {/* <div className={styles.socials}>
                        <ul>
                            <li>Github</li>
                            <li>Twitter</li>
                            <li>LinkedIn</li>
                        </ul>
                    </div> */}
                </div>
            </motion.section>
            <div ref={endOfFooterRef}/>
        </>
    );
};

export default Footer;
