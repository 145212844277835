import React from "react";
import { motion, useAnimation } from "framer-motion";

import styles from "./AnimatedButton.module.scss";

const AnimatedButton = ({ content }) => {
    const controls = useAnimation();

    const shapeMotion = {
        hoverStart: {
            x: 0,
            transition: {
                duration: 0.5,
            },
        },
        hoverOut: {
            x: -150,
            transition: {
                duration: 0.5,
            },
        },
        hoverReset: {
            x: 200,
            transition: {
                duration: 0,
            },
        },
    };

    return (
        <>
            <motion.div
                className={styles.buttonContainer}
                animate={controls}
                onHoverStart={async () => {
                    await controls.start("hoverStart");
                }}
                onHoverEnd={async () => {
                    await controls.start("hoverOut");
                    await controls.start("hoverReset");
                }}
            >
                <div className={styles.button}>
                    <motion.div variants={shapeMotion} className={styles.shape}></motion.div>
                    <p className={styles.text}>{content}</p>
                </div>
            </motion.div>
        </>
    );
};

export default AnimatedButton;
